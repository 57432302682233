import { Box, Grid, Stack, Typography } from "@mui/material";
import { Item } from "../utils/ItemStyle";
import { TransactionList } from "../Components/others/TransactionList";
import { useEffect } from "react";
import CardsComp from "../Components/Wallet/CardsComp";
import DepositWithDraw from "../Components/others/DepositWithDraw";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Wallet = () => {
  // const [totalDeposit, setTotalDeposit] = useState(0);
  // const [totalExpenses, setTotalExpenses] = useState(0);
  // const [transactions, setTransactions] = useState<ITransactions[]>([]);

  const { transactions, totalDeposit, totalExpenses } = useSelector(
    (state: any) => state.form
  );

  // const fetchDepositedAmount = async () => {
  //   await AxiosInstance.get("/transactions/deposit")
  //     .then((res) => {
  //       setTotalDeposit(res.data.totalDeposited);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  // const fetchExpensesAmount = async () => {
  //   await AxiosInstance.get("/transactions/expenses")
  //     .then((res) => {
  //       setTotalExpenses(res.data.totalExpenses);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const fetchTransactions = async () => {
  //   await AxiosInstance.get("/transactions")
  //     .then((res) => {
  //       setTransactions(res.data.transactions);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const last5Transactions = transactions
    .slice(-5) 
    .reverse();

  useEffect(() => {
    // fetchDepositedAmount();
    // fetchExpensesAmount();
    // fetchTransactions();
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, md: 0 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },
          }}
        >
          <Grid xs={16} md={5}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "250px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Transactions History
                  </Typography>
                  <Link
                    to="/transactions"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Typography fontWeight={"bold"} sx={{ cursor: "pointer" }}>
                      See All
                    </Typography>
                  </Link>
                </Box>
                <Stack sx={{ mt: "20px" }} gap={1}>
                  {last5Transactions ? (
                    last5Transactions.map((data: any, index: number) => (
                      <TransactionList
                        key={index}
                        amount={data.amount}
                        description={data.description}
                        date={data && data.createdAt}
                      />
                    ))
                  ) : (
                    <Typography>No transaction</Typography>
                  )}
                </Stack>
              </Stack>
            </Item>
          </Grid>
          <Grid md={11} xs={16} gap={2}>
            <DepositWithDraw />
            <Item
              sx={{
                mt: "30px",
                overflow: "hidden",
                textAlign: "center",
                minHeight: "300px",
              }}
            >
              <CardsComp income={totalDeposit} expenses={totalExpenses} />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Wallet;

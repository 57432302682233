import { Box, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useColors } from "../../utils/useColor";
import useUser from "../../lib/useUser";
import { formatNumber } from "../../utils/FormatedNumber";
import { MdCallReceived } from "react-icons/md";
import { MdCallMade } from "react-icons/md";
import ButtonComp from "../Buttons/ButtonComp";
import ActionModal from "../Modals/ActionModal";
import axiosInstances from "../../hooks/axiosInstances";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  setLoading,
  setMessage,
  setSuccess,
} from "../../utils/formSlice";
import { IAccountRecord } from "../../types/common";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

interface Props {
  income: number;
  expenses: number;
}

const CardsComp: FC<Props> = ({ income, expenses }) => {
  const user = useUser((state) => state.user);
  const { primary, secondary, success, error } = useColors();
  const [open, setOpen] = useState(false);
  const [accountNumber, setAccountNumber] = useState<IAccountRecord>();
  // const [balance, setBalance] = useState(0);
  const [count, setCount] = useState(0);

  const { userBalance } = useSelector((state: any) => state.form);

  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(!open);
  };
  const style = {
    gradient: `linear-gradient(45deg, ${primary.dark}, ${secondary.dark})`,
  };

  const handleSubmit = () => {
    dispatch(setLoading(true));
    axiosInstances
      .post("/users/virtualaccount")
      .then((res) => {
        dispatch(setMessage(res.data.message));
        fetchAccountNumber();
        handleClose();
        dispatch(setSuccess());
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      })
      .catch((error) => {
        dispatch(setMessage(error.response.data.error));
        dispatch(setLoading(false));
        setTimeout(() => {
          dispatch(clearMessage());
        }, 3000);
      });
  };

  const fetchAccountNumber = () => {
    axiosInstances
      .get("/accountnumber")
      .then((res) => {
        setAccountNumber(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const fetchBalance = () => {
  //   axiosInstances
  //     .get("/users/balance/user")
  //     .then((res) => {
  //       setBalance(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handlePrev = (index: number) => {
    if (index > 0) {
      setCount(--index);
    }
  };

  const handleNext = (index: number, max: number) => {
    max -= 1;
    if ((index > 0 || index === 0) && index < max) {
      setCount(++index);
    }
  };

  useEffect(() => {
    fetchAccountNumber();
    // fetchBalance();
  }, []);

  return (
    <>
      <ActionModal
        open={open}
        message="To create a virtual account number, we need to use your Bank Verification Number (BVN) and National Identification Number (NIN). By proceeding, you consent to our access of your BVN and NIN solely for this purpose. Your information will be handled securely and in accordance with our privacy policy. Do you agree to proceed? "
        handleClose={handleClose}
        status="error"
        confirmAction={handleSubmit}
      />
      <Box
        sx={{
          minHeight: { lg: "230px", xs: "230px" },
          width: "100%",
          display: "flex",
          gap: 2,
          flexDirection: { lg: "row", xs: "column" },
          justifyContent: { xs: "center", lg: "space-between" },
          alignContent: { xs: "center", lg: "none" },
          alignSelf: "center",
          overflow: "visible",
          padding: { xs: "0px", lg: "0" },
        }}
      >
        <Box
          sx={{
            maxWidth: "100%",
            width: { lg: "50%", xs: "95%" },
            minHeight: "200px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            border: 1,
            borderColor: "#DFE1E4",
            p: 2,
          }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Wallet Balance
            </Typography>
            <Typography fontWeight={"bold"} variant="h6">
              {formatNumber(userBalance)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* Total Income */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Stack
                sx={{
                  width: "40px",
                  height: "40px",
                  background: success.light,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdCallReceived size={20} color="white" />
              </Stack>

              <Typography sx={{ fontWeight: "bold" }}>Total Income</Typography>
              <Typography fontWeight={"bold"} variant="h6">
                {formatNumber(income)}
              </Typography>
            </Box>
            {/* Total Expenses */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Stack
                sx={{
                  width: "40px",
                  height: "40px",
                  background: error.light,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdCallMade size={20} color="white" />
              </Stack>

              <Typography sx={{ fontWeight: "bold" }}>
                Total Expenses
              </Typography>
              <Typography fontWeight={"bold"} variant="h6">
                {formatNumber(expenses)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            width: { lg: "50%", xs: "95%" },
            minHeight: "230px",
            borderRadius: "20px",
            border: 1,
            borderColor: "#D9DCDE",
            backgroundImage: style.gradient,
            color: "#fff",
            position: "relative",
            overflow: "hidden",
            textAlign: "left",
            p: 2,
            display: "flex",
            // flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Absolute Element */}
          <Box
            sx={{
              width: "150px",
              height: "70px",
              background: primary.dark,
              borderTopRightRadius: "50px",
              borderBottomRightRadius: "50px",
              opacity: 0.1,
              transform: "rotate(135deg)",
              position: "absolute",
              top: "-10px",
              right: "-10px",
            }}
          ></Box>
          <Box
            sx={{
              width: "150px",
              height: "70px",
              background: primary.dark,
              borderTopRightRadius: "50px",
              borderBottomRightRadius: "50px",
              opacity: 0.1,
              transform: "rotate(135deg)",
              position: "absolute",
              top: "89px",
              right: "-50px",
            }}
          ></Box>
          {/* Absolute Element */}

          {accountNumber ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>{`${count + 1} / ${
                  accountNumber.accounts.length
                }`}</Typography>
                <Box sx={{ display: "flex", gap: 3, zIndex: 999 }}>
                  <KeyboardDoubleArrowLeftOutlinedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handlePrev(count)}
                  />
                  <KeyboardDoubleArrowRightOutlinedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleNext(count, accountNumber.accounts.length)
                    }
                  />
                </Box>
              </Box>
              <Box sx={{}}>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Account Number:{" "}
                  </Typography>
                  <Typography>
                    {accountNumber.accounts[count].accountNumber}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Account Name:{" "}
                  </Typography>
                  <Typography>{`${user.fullname}-${accountNumber.accountName}`}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <Typography sx={{ fontWeight: "bold" }}>Bank: </Typography>
                  <Typography>
                    {accountNumber.accounts[count].bankName}
                  </Typography>
                </Box>
                <Typography sx={{ fontStyle: "italic" }}>
                  Fee charge 2%
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box>
              <ButtonComp
                variant="contained"
                text="Get Virtual Account"
                type="button"
                disableElevation
                onClick={handleOpen}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CardsComp;

// formSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  IProducts,
  ITransactions,
  IProductPrice,
  IUsers,
} from "../types/common";

interface FormState {
  isLoading: boolean;
  isSubmitted: boolean;
  isError: boolean;
  isSuccess: boolean;
  ismessage: string;
  transactions: ITransactions[];
  totalDeposit: number;
  totalExpenses: number;
  userBalance: number;
  products: IProducts[];
  productPrice: IProductPrice[];
  userData: IUsers[];
}

const initialState: FormState = {
  isLoading: false,
  isSubmitted: false,
  isError: false,
  isSuccess: false,
  ismessage: "",
  transactions: [],
  totalDeposit: 0,
  totalExpenses: 0,
  userBalance: 0,
  products: [],
  productPrice: [],
  userData: [],
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setLoading: (state: any, actions) => {
      state.isLoading = actions.payload;
    },
    setSubmitted: (state: any) => {
      state.isSubmitted = true;
    },
    setError: (state: any) => {
      state.isError = true;
    },
    setSuccess: (state: any) => {
      state.isSuccess = true;
    },
    setMessage: (state: any, actions: any) => {
      state.ismessage = actions.payload;
    },
    clearMessage: (state) => {
      state.ismessage = "";
      state.isSuccess = false;
      state.isSubmitted = false;
    },
    setTransactions: (state, actions) => {
      state.transactions = actions.payload;
    },
    setTotalDeposit: (state, action) => {
      state.totalDeposit = action.payload;
    },
    setTotalExpenses: (state, action) => {
      state.totalExpenses = action.payload;
    },
    setUserBalance: (state, action) => {
      state.userBalance = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProductPrice: (state, action) => {
      state.productPrice = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const {
  setLoading,
  setSubmitted,
  setError,
  setSuccess,
  setMessage,
  clearMessage,
  setTransactions,
  setTotalDeposit,
  setTotalExpenses,
  setUserBalance,
  setProducts,
  setProductPrice,
  setUserData,
} = formSlice.actions;
export const selectForm = (state: any) => state.form;
export default formSlice.reducer;

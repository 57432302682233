/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import {
  setLoading,
} from "../../utils/formSlice";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../lib/useUser";

interface AvatarUploaderProps {
  imageUrl: string;
}

const AvatarUploader: React.FC<AvatarUploaderProps> = ({ imageUrl }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const updateUser = useUser((state) => state.updateUser);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isLoading, isSubmitted, isSuccess, ismessage } = useSelector(
    (state: any) => state.form
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const file = event.target.files?.[0];
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result as string);
        submitImageToDatabase(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const submitImageToDatabase = async (file: any) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", file);
    console.log(selectedImage);
    // AxiosInstance.put(`/users`, formData)
    //   .then((response: any) => {
    //     handleClick();
    //     console.log(response);
    //     dispatch(setMessage(response.data.message));
    //     dispatch(setSuccess());
    //     dispatch(setSubmitted());
    //     dispatch(setLoading(false));
    //     updateUser(response.data.Newuser);
    //   })
    //   .catch((error) => {
    //     handleClick();
    //     dispatch(setMessage(error.message));
    //     dispatch(setLoading(false));
    //   });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSubmitted && isSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {ismessage}
        </Alert>
      </Snackbar>
      <div style={{ position: "relative", display: "inline-block" }}>
        <Avatar
          alt="User Avatar"
          src={selectedImage || imageUrl}
          sx={{ width: 200, height: 200 }}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <IconButton
          style={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleUploadClick}
        >
          <CloudUploadIcon color="primary" />
        </IconButton>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          ref={fileInputRef}
        />
      </div>
    </>
  );
};

export default AvatarUploader;

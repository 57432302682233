/* eslint-disable react-hooks/exhaustive-deps */
// import { DateRange } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ITransactions, IUsers } from "../types/common";
import TransactionTable from "../Components/Tables/TransactionTable";
import useUser from "../lib/useUser";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Data from "../Components/Receipt/Data";
import { useSelector } from "react-redux";
import { useColors } from "../utils/useColor";
import Deposit from "../Components/Receipt/Deposit";
import { useFormik } from "formik";
import Airtime from "../Components/Receipt/Airtime";

const Transaction = () => {
  const user = useUser((state) => state.user);
  const [singleTransact, setSingleTransact] = useState<ITransactions>();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editId, setEditId] = useState("");
  const contentRef = useRef(null);
  const { primary } = useColors();
  const { transactions, userData } = useSelector((state: any) => state.form);
  // const [userDatas, setUserDatas] = useState([]);
  const [options, setOptions] = useState(userData);

  const handleClickOpen = () => {
    if (openDialog) {
      setEditId("");
    }
    setOpenDialog(!openDialog);
  };

  const handleOpen = () => {
    setOpenEditDialog(!openEditDialog);
  };

  const handleDownloadAsImage = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = imgData;
      link.download = "receipt.png";
      link.click();
    }
  };

  const handleDownloadAsPDF = async () => {
    if (contentRef.current) {
      const canvas = await html2canvas(contentRef.current);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("receipt.pdf");
    }
  };

  const handleEdit = (id: string) => {
    setEditId(id);
    handleClickOpen();
  };

  const formik = useFormik({
    initialValues: {
      userId: "",
    },
    onSubmit: (value) => {
      console.log(value);
    },
  });

  const handleSearch = (event: any) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredOptions: any = userData
      .filter((data: any) =>
        `${data.fullname} ${data.username}`.toLowerCase().includes(searchTerm)
      )
      .slice(0, 5);
    setOptions(filteredOptions);
  };

  useEffect(() => {
    if (editId !== "") {
      const filterTransaction = transactions.filter(
        (data: any) => data._id === editId || data.id === editId
      );

      setSingleTransact(filterTransaction[0]);
      console.log(filterTransaction[0]);
    }
    // setUserDatas(userData);
  }, [editId, userData]);

  return (
    <>
      <HeaderTextButton
        headerName="Transactions"
        buttonName="Create New"
        onclickfunction={handleOpen}
      />

      {user.role === "ADMIN" && (
        <Stack
          sx={{
            bgcolor: "#fff",
            mt: "10px",
            py: "10px",
            displa: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box>
            <Button sx={{ fontWeight: "bold" }}>All</Button>
            <Button sx={{ fontWeight: "bold" }}>Deposited</Button>
            <Button sx={{ fontWeight: "bold" }}>Withdrew</Button>
          </Box>
          <Box>
            <Typography>Date and Time</Typography>
          </Box>
        </Stack>
      )}

      <Box mt="40px">
        <TransactionTable data={transactions} handleEdit={handleEdit} />
      </Box>

      <Dialog
        open={openEditDialog}
        onClose={handleOpen}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Create Transaction
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Enter a concise yet descriptive transaction details to create
            record.
          </DialogContentText>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Stack sx={{ gap: 2 }}>
              {/* <Box>
                <FormControl
                  fullWidth
                  error={formik.touched.userId && Boolean(formik.errors.userId)}
                >
                  <InputLabel id="demo-select-small-label">Users</InputLabel>
                  <Select
                    labelId="Users"
                    id="userId"
                    name="userId"
                    label="Users"
                    value={formik.values.userId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userId && Boolean(formik.errors.userId)
                    }
                    sx={{ textAlign: "left" }}
                  >
                    {userData.map((data: any, index: number) => (
                      <MenuItem key={index} value={data._id}>
                        {`${data.fullname} (${data.username})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {formik.touched.userId && formik.errors.userId && (
                  <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                    {formik.errors.userId}
                  </Typography>
                )}
              </Box> */}
              {/* <Box>
                <FormControl
                  fullWidth
                  error={formik.touched.userId && Boolean(formik.errors.userId)}
                >
                  <Autocomplete
                    id="userId"
                    options={options}
                    getOptionLabel={(option: IUsers) =>
                      `${option.fullname} ${option.username}`
                    }
                    value={formik.values.userId}
                    onChange={(event: any, value: any) =>
                      formik.setFieldValue("userId", value)
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="userId"
                        onChange={handleSearch}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value &&
                      value.map((option: any, index) => (
                        <Chip
                          label={`${option.fullname} ${option.username}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </FormControl>
                {formik.touched.userId && formik.errors.userId && (
                  <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                    {formik.errors.userId}
                  </Typography>
                )}
              </Box> */}
            </Stack>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDialog}
        onClose={handleClickOpen}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle sx={{ fontWeight: "bold" }}>
          Download Transaction Receipt
        </DialogTitle>
        <DialogContent>
          <DialogContentText mb={"15px"}>
            Overview of user's profile.
          </DialogContentText>

          <Box
            ref={contentRef}
            sx={{
              border: "1px solid #ccc",
              padding: 2,
              // borderRadius: "10px",
              width: "400px",
              margin: "auto",
              background: "#fff",
            }}
          >
            {singleTransact ? (
              singleTransact?.description === "Data purchase" ? (
                <Data data={singleTransact} />
              ) : singleTransact?.description === "Deposit" ||
                singleTransact?.description === "Expenses" ? (
                <Deposit data={singleTransact} />
              ) : (
                singleTransact?.description === "Airtime Recharge" && (
                  <Airtime data={singleTransact} />
                )
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <CircularProgress sx={{ color: primary.dark }} />
                <Typography>Loading.....</Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 3,
              pt: 3,
              width: "375px",
              margin: "auto",
            }}
          >
            <Button variant="contained" fullWidth onClick={handleDownloadAsPDF}>
              Download PDF
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleDownloadAsImage}
            >
              Download Image
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Transaction;

import waec from "../assets/Images/waec.png";
import neco from "../assets/Images/neco.png";
import nabteb from "../assets/Images/nabteb.png";
import { IProducts, ITransactions } from "../types/common";

export function removeLeadingSlash(str: any) {
  return str.replace(/^\/+/, "");
}

const formatDate = (timestamp: string): string => {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return date.toLocaleDateString("en-GB", options);
};

export const convertTimestampToDate = (timestamp: string): string => {
  return formatDate(timestamp);
};

export const fetchImage = (data?: string) => {
  let Images;

  if (data === "NECO") {
    Images = neco;
  }

  if (data === "WAEC") {
    Images = waec;
  }

  if (data === "NABTEB") {
    Images = nabteb;
  }

  return Images;
};

export const removeDuplicates = (array: IProducts[]) => {
  const seen = new Set<string>();
  return array.filter((item) => {
    const id = item.category;
    if (seen.has(id)) {
      return false;
    } else {
      seen.add(id);
      return true;
    }
  });
};

export function formatTitle(text: string = "") {
  // Use a regular expression to remove the price information
  const nameOnly = text.replace(/\sN\d+,\d+|\sN\d+/g, "");
  return nameOnly;
}

export function getDataByIndex(
  data: string,
  index: number
): string | undefined {
  const parts = data.split("_"); // Split the string by _
  return parts[index - 1]; // Return the part at the specified index (1-based)
}

export function formatDateTime(isoString: string): string {
  const date = new Date(isoString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  const amPm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert to 12-hour format

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${amPm}`;
}


export const filterTransactionsLast28Days = (transactions: ITransactions[]): ITransactions[] => {
  const today = new Date();
  const twentyEightDaysAgo = new Date();
  twentyEightDaysAgo.setDate(today.getDate() - 28);

  return transactions.filter(transaction => {
    const transactionDate = new Date(transaction.createdAt);
    return transactionDate >= twentyEightDaysAgo;
  });
};

export const processTransactionData = (transactions: ITransactions[]) => {
  // Step 1: Extract unique descriptions
  const descriptionSet = new Set(transactions.map(t => t.description));
  const labels = Array.from(descriptionSet);

  // Step 2: Calculate total amounts for each description
  const datasets = labels.map(label => {
    return transactions
      .filter(transaction => transaction.description === label)
      .reduce((total, transaction) => total + transaction.amount, 0);
  });

  return { labels, datasets };
};


/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { InputText } from "../Components/TextFields/InputText";
import { Item } from "../utils/ItemStyle";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  setLoading,
  setMessage,
  setSubmitted,
  setSuccess,
} from "../utils/formSlice";
import { LoadingButton } from "@mui/lab";
import axiosInstances from "../hooks/axiosInstances";
import { validateEducational } from "../utils/validationScheme";
import useUser from "../lib/useUser";
import {
  IPinsType,
} from "../types/common";
import { TransactionList } from "../Components/others/TransactionList";
import EducationTable from "../Components/Tables/EducationTable";
import {
  fetchTransactions,
  fetchUserBalance,
  fetchDepositedAmount,
  fetchExpensesAmount,
} from "../utils/constants";

const Education = () => {
  const user = useUser((state) => state.user);
  const [pins, setPins] = useState<IPinsType[]>([]);
  const { isLoading, transactions, products, productPrice } = useSelector(
    (state: any) => state.form
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      pinType: "",
      quantity: 1,
      amount: 0,
    },
    validationSchema: validateEducational,
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstances
        .post("users/educational", value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          fetchTransactions(dispatch);
          fetchUserBalance(dispatch);
          fetchDepositedAmount(dispatch);
          fetchExpensesAmount(dispatch);
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));

          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const fetchPins = async (id?: string) => {
    await axiosInstances
      .get(`pin/${id}`)
      .then((res) => {
        setPins(res.data.pins);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterProducts = products.filter(
    (data: any) =>
      data.serviceName === "Scratch Cards" && data.visibility === true
  );

  useEffect(() => {
    let selectedPrice = 0;
    if (formik.values.pinType) {
      const filterPrice = productPrice.filter(
        (data: any) => data.product === formik.values.pinType
      );
      console.log(filterPrice);

      selectedPrice = filterPrice[0].price;

      const amount = selectedPrice * formik.values.quantity;
      formik.setFieldValue("amount", amount);
    }

    if (formik.values.quantity && formik.values.pinType) {
      const amount = formik.values.quantity * selectedPrice;
      formik.setFieldValue("amount", amount);
    }

    if (user.role === "USER") {
      fetchPins(user._id);
    } else {
      fetchPins("");
    }
  }, [formik.values.pinType, formik.values.quantity, user]);

  const filteredTransaction = transactions.filter(
    (item: any) => item.description === "Scratch card purchase"
  );

  const last5Transactions = filteredTransaction.slice(-5).reverse();

  return (
    <>
      <HeaderTextButton headerName="Educational Purchase" />

      <Box sx={{ flexGrow: 0, mt: "30px" }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, sm: 1, md: 0 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column", sm: "row" },
          }}
        >
          <Grid xs={16} sm={8} md={8}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Item
                sx={{
                  overflow: "hidden",
                  mr: { lg: "20px", xs: "0px" },
                  p: 0,
                }}
              >
                <Box sx={{ mx: "30px", my: "40px" }}>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.pinType && Boolean(formik.errors.pinType)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Pin Type
                      </InputLabel>
                      <Select
                        labelId="Pin Type"
                        id="pinType"
                        name="pinType"
                        label="Pin Type"
                        value={formik.values.pinType}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.pinType &&
                          Boolean(formik.errors.pinType)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {filterProducts &&
                          filterProducts.map((data: any, index: number) => (
                            <MenuItem key={index} value={data._id}>
                              {data.product}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.pinType && formik.errors.pinType && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {formik.errors.pinType}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.quantity &&
                        Boolean(formik.errors.quantity)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Quantity
                      </InputLabel>
                      <Select
                        id="quantity"
                        name="quantity"
                        label="Quantity"
                        value={formik.values.quantity}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.quantity &&
                          Boolean(formik.errors.quantity)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.quantity && formik.errors.quantity && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.quantity}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <InputText
                      id="amount"
                      label="Amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.touched.amount && formik.errors.amount}
                      disabled
                    />
                  </Box>
                  {isLoading ? (
                    <LoadingButton loading variant="outlined" fullWidth>
                      Submit
                    </LoadingButton>
                  ) : (
                    <ButtonComp
                      variant="contained"
                      text="Proceed"
                      type="submit"
                      fullWidth
                      disableElevation
                    />
                  )}
                </Box>
              </Item>
            </form>
          </Grid>

          <Grid xs={16} sm={8} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "315px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Transactions History
                  </Typography>
                  <Link
                    to="/transactions"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Typography fontWeight={"bold"} sx={{ cursor: "pointer" }}>
                      See All
                    </Typography>
                  </Link>
                </Box>
                <Stack sx={{ mt: "20px" }} gap={1}>
                  {last5Transactions ? (
                    last5Transactions.map((data: any, index: number) => (
                      <TransactionList
                        key={index}
                        amount={data.amount}
                        description={data.description}
                        date={data && data.createdAt}
                      />
                    ))
                  ) : (
                    <Typography>No transaction</Typography>
                  )}
                </Stack>
              </Stack>
            </Item>
          </Grid>
        </Grid>

        <Box sx={{ mt: "20px" }}>
          <EducationTable data={pins} />
        </Box>
      </Box>
    </>
  );
};

export default Education;

import React, { FC } from "react";
import { ITransactions } from "../../types/common";
import { Box, Typography, Grid, Stack } from "@mui/material";
import { formatNumber } from "../../utils/FormatedNumber";
import { formatDateTime } from "../../utils/utils";
import useUser from "../../lib/useUser";
import { MdCallReceived } from "react-icons/md";
import { MdCallMade } from "react-icons/md";
import { useColors } from "../../utils/useColor";

interface Props {
  data: ITransactions;
}

const Deposit: FC<Props> = ({ data }) => {
  const user = useUser((state) => state.user);

  const { success, error } = useColors();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #ccc",
          pb: 2,
        }}
      >
        <Box>
          <Typography variant="body2">Powered by</Typography>
          <Typography fontWeight="bold">www.zudovite.com.ng</Typography>
        </Box>
        <Stack
          sx={{
            width: "50px",
            height: "50px",
            background:
              data.description === "Deposit" ? success.light : error.light,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.description === "Deposit" ? (
            <MdCallReceived size={25} color="white" />
          ) : (
            <MdCallMade size={25} color="white" />
          )}
        </Stack>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container>
          <Grid
            item
            xs={5}
            sx={{
              textAlign: "left",
              gap: 2,
              display: "flex",
              flexDirection: "column",
              "& .text": {
                borderBottom: "1px solid #ccc",
              },
            }}
          >
            <Typography className="text">Description</Typography>
            <Typography className="text">Transaction ID</Typography>
            <Typography className="text">Amount</Typography>
            <Typography className="text">Date</Typography>
            <Typography className="text">Status</Typography>
            <Typography>Payer</Typography>
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              textAlign: "right",
              gap: 2,
              display: "flex",
              flexDirection: "column",
              "& .text": {
                borderBottom: "1px solid #ccc",
              },
            }}
          >
            <Typography className="text">{data?.description}</Typography>
            <Typography className="text">{data?.tr_ref}</Typography>
            <Typography className="text">
              {data?.description === "Deposit"
                ? `+${formatNumber(data?.amount ?? 0)}`
                : `-${formatNumber(data?.amount ?? 0)}`}
            </Typography>
            <Typography className="text">
              {formatDateTime(data?.createdAt ?? "")}
            </Typography>
            <Typography className="text">
              {data?.status === true ? "Successful" : "Failed"}
            </Typography>
            <Typography>
              {user.role === "ADMIN" ? data.userEmail : user.email}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Deposit;

import React, { FC, useEffect, useState } from "react";
import { ITransactions } from "../../types/common";
import { Box, Typography, Grid } from "@mui/material";
import { formatNumber } from "../../utils/FormatedNumber";
import { formatDateTime, getDataByIndex } from "../../utils/utils";
import mtnLogo from "../../assets/Images/mtn.png";
import gloLogo from '../../assets/Images/glo.png'
import airtelLogo from '../../assets/Images/airtel.png'

import useUser from "../../lib/useUser";

interface Props {
  data: ITransactions;
}

const Data: FC<Props> = ({ data }) => {
  const user = useUser((state) => state.user);
  const [imageLogo, setImageLogo] = useState<string>("");
  useEffect(() => {
    const image = getDataByIndex(data?.FLW_ref ?? "", 1);

    if (image === "MTN") {
      setImageLogo(mtnLogo);
    }
    if (image === "GLO") {
      setImageLogo(gloLogo);
    }
    if (image === "AIRTEL") {
      setImageLogo(airtelLogo);
    }
  }, [data]);

  //
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #ccc",
          pb: 2,
        }}
      >
        <Box>
          <Typography variant="body2">Powered by</Typography>
          <Typography fontWeight="bold">www.zudovite.com.ng</Typography>
        </Box>
        <Box
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            background: "primary",
            overflow: "hidden",
            border: "none",
            cursor: "pointer",
          }}
        >
          <img
            src={imageLogo}
            alt={getDataByIndex(data?.FLW_ref ?? "", 1)}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Grid container>
          <Grid
            item
            xs={5}
            sx={{
              textAlign: "left",
              gap: 2,
              display: "flex",
              flexDirection: "column",
              "& .text": {
                borderBottom: "1px solid #ccc",
              },
            }}
          >
            <Typography className="text">Description</Typography>
            <Typography className="text">Transaction ID</Typography>
            <Typography className="text">Service Provider</Typography>
            <Typography className="text">Mobile No</Typography>
            <Typography className="text">Data Volume</Typography>
            <Typography className="text">Amount</Typography>
            <Typography className="text">Date</Typography>
            <Typography className="text">Status</Typography>
            <Typography>Payer</Typography>
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              textAlign: "right",
              gap: 2,
              display: "flex",
              flexDirection: "column",
              "& .text": {
                borderBottom: "1px solid #ccc",
              },
            }}
          >
            <Typography className="text">{data?.description}</Typography>
            <Typography className="text">{data?.tr_ref}</Typography>
            <Typography className="text">
              {getDataByIndex(data?.FLW_ref ?? "", 1)}
            </Typography>
            <Typography className="text">
              {getDataByIndex(data?.transaction_Id ?? "", 2)}
            </Typography>
            <Typography className="text">
              {getDataByIndex(data?.FLW_ref ?? "", 2)}
            </Typography>
            <Typography className="text">
              {formatNumber(data?.amount ?? 0)}
            </Typography>
            <Typography className="text">
              {formatDateTime(data?.createdAt ?? "")}
            </Typography>
            <Typography className="text">
              {data?.status === true ? "Successful" : "Failed"}
            </Typography>
            <Typography>{user.role === 'ADMIN' ? data.userEmail : user.email}</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Data;

import axiosInstances from "../hooks/axiosInstances";
import {
  setProducts,
  setTotalDeposit,
  setTotalExpenses,
  setTransactions,
  setUserBalance,
  setProductPrice,
  setUserData,
} from "./formSlice";
import { AppDispatch } from "./store";

export const fetchTransactions = async (dispatch: AppDispatch) => {
  try {
    const response = await axiosInstances.get("/transactions");
    dispatch(setTransactions(response.data.transactions));
  } catch (error) {
    console.error("Error fetching transactions:", error);
  }
};

export const fetchUserBalance = async (dispatch: AppDispatch) => {
  axiosInstances
    .get("/users/balance/user")
    .then((res) => {
      dispatch(setUserBalance(res.data as number));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchDepositedAmount = async (dispatch: AppDispatch) => {
  await axiosInstances
    .get("/transactions/deposit")
    .then((res) => {
      dispatch(setTotalDeposit(res.data.totalDeposited));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const fetchExpensesAmount = async (dispatch: AppDispatch) => {
  await axiosInstances
    .get("/transactions/expenses")
    .then((res) => {
      dispatch(setTotalExpenses(res.data.totalExpenses));
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const fetchProducts = async (dispatch: AppDispatch) => {
  await axiosInstances
    .get("/products")
    .then((res) => {
      dispatch(setProducts(res.data.products));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchPrice = async (dispatch: AppDispatch) => {
  await axiosInstances
    .get("/prices")
    .then((res) => {
      dispatch(setProductPrice(res.data.price));
    })
    .catch((error) => {
      console.log(error);
    });
};

export  const fetchUsers = async (dispatch: AppDispatch) => {
  await axiosInstances
    .get("/users")
    .then((res) => {
      dispatch(setUserData(res.data.users))
    })
    .catch(function (error) {
      console.log(error);
    });
};

type PinType = "WAEC" | "NECO" | "NABTEB";
export const PinPrice: Record<PinType, { price: number }> = {
  WAEC: {
    price: 3500,
  },
  NECO: {
    price: 1500,
  },
  NABTEB: {
    price: 1000,
  },
};

export const banks = [
  { bankName: "Access Bank", bankCode: "044" },
  { bankName: "Fidelity Bank", bankCode: "070" },
  { bankName: "Sterling Bank", bankCode: "232" },
  { bankName: "Guaranty Trust Bank", bankCode: "058" },
  { bankName: "United Bank for Africa", bankCode: "033" },
  { bankName: "Zenith Bank", bankCode: "057" },
  { bankName: "Eco Bank", bankCode: "050" },
  { bankName: "Heritage Bank", bankCode: "030" },
  { bankName: "First City Monument Bank", bankCode: "214" },
  { bankName: "Keystone Bank", bankCode: "082" },
  { bankName: "Polaris Bank", bankCode: "076" },
  { bankName: "Stanbic IBTC Bank", bankCode: "221" },
  { bankName: "Unity Bank", bankCode: "215" },
  { bankName: "Wema Bank", bankCode: "035" },
  { bankName: "First Bank of Nigeria", bankCode: "011" },
  { bankName: "SunTrust Bank", bankCode: "100" },
  { bankName: "Jaiz Bank", bankCode: "301" },
  { bankName: "Union Bank of Nigeria", bankCode: "032" },
  { bankName: "Standard Chartered", bankCode: "068" },
];

import { Box, Typography, Stack, Grid, Button } from "@mui/material";
import useUser from "../../lib/useUser";
import { accountLevel } from "../../utils/accountLevel";
import { formatNumber } from "../../utils/FormatedNumber";
import { useColors } from "../../utils/useColor";
import ErrorImage from "../../assets/Images/welcome.svg";
import PaidIcon from "@mui/icons-material/Paid";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import { useSelector } from "react-redux";
import MyChart from "../Charts/MyChart";
import {
  filterTransactionsLast28Days,
  processTransactionData,
} from "../../utils/utils";
import { TransactionList } from "../others/TransactionList";

const UserDash = () => {
  const user = useUser((state) => state.user);
  // const [transactions, setTransactions] = useState<ITransactions[]>([]);
  // const [balance, setBalance] = useState(0)

  const { userBalance, transactions } = useSelector((state: any) => state.form);

  // const fetchTransactions = async () => {
  //   await axiosInstances
  //     .get("/transactions")
  //     .then((res) => {
  //       setTransactions(res.data.transactions);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const fetchBalance = () => {
  //   axiosInstances
  //     .get("/users/balance/user")
  //     .then((res) => {
  //       setBalance(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   fetchTransactions();
  //   fetchBalance()
  // }, []);
  const last5Transactions = transactions.slice(-8).reverse();

  const filterTransaction = transactions.filter(
    (item: any) => item.status === true
  );

  const last28Transaction = filterTransactionsLast28Days(transactions);

  const { labels, datasets } = processTransactionData(last28Transaction);

  const { primary } = useColors();
console.log(labels, datasets)
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="center"
        sx={{
          mx: { md: 10, xs: 0 },
          background: "#fff",
          borderRadius: "15px",
          overflow: "hidden",
          px: { md: 5, xs: 2 },
          py: 2,
        }}
      >
        <Box flexGrow={1} gap={3}>
          <Typography sx={{ fontWeight: "bold" }}>
            Hello {user.username}
          </Typography>
          <Typography variant="body1" sx={{ my: 3 }}>
            Welcome to ZudoVite, where you confiniently get access to unlimited
            digital services.
          </Typography>
          <Button
            variant="contained"
            sx={{ background: "primary" }}
            disableElevation
          >
            Explore Services
          </Button>
        </Box>
        <img src={ErrorImage} alt="" width={150} />
      </Box>
      <Stack>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          gap={3}
          justifyContent="center"
          py={5}
        >
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              background: "#fff",
              minWidth: "250px",
              height: "150px",
              borderLeft: `7px solid ${primary.dark}`,
              borderRadius: "7px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              pl={{ xs: "30px", md: "10px" }}
              height={"100%"}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h5" fontWeight="bold">
                {formatNumber(userBalance)}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                Wallet Balance
              </Typography>
            </Box>
            <PaidIcon
              sx={{
                fontSize: "90px",
                position: "absolute",
                top: "-10%",
                right: "-5%",
                opacity: 0.1,
                transform: "rotateZ(-45deg)",
                color: primary.dark,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              background: "#fff",
              minWidth: "250px",
              height: "150px",
              borderLeft: `7px solid ${primary.dark}`,
              borderRadius: "7px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              pl={{ xs: "20px", md: "10px" }}
              height={"100%"}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h5" fontWeight="bold">
                {filterTransaction.length}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                Transactions
              </Typography>
            </Box>
            <AccountBalanceWalletIcon
              color={"primary"}
              sx={{
                fontSize: "90px",
                position: "absolute",
                top: "-10%",
                right: "-5%",
                opacity: 0.1,
                transform: "rotateZ(-45deg)",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              background: "#fff",
              minWidth: "250px",
              height: "150px",
              borderLeft: `7px solid ${primary.dark}`,
              borderRadius: "7px",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              pl={{ xs: "20px", md: "10px" }}
              height={"100%"}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h5" fontWeight="bold">
                {accountLevel(filterTransaction.length)}
              </Typography>
              <Typography variant="h6" fontWeight="bold">
                Account Level
              </Typography>
            </Box>
            <LocalPoliceOutlinedIcon
              color={"primary"}
              sx={{
                fontSize: "90px",
                position: "absolute",
                top: "-10%",
                right: "-5%",
                opacity: 0.1,
                transform: "rotateZ(-45deg)",
              }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Box sx={{ overflow: "hidden", background: "#fff", p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MyChart labels={labels} datasets={datasets} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack sx={{ mt: "20px" }} gap={1}>
              {last5Transactions ? (
                last5Transactions.map((data: any, index: number) => (
                  <TransactionList
                    key={index}
                    amount={data.amount}
                    description={data.description}
                    date={data && data.createdAt}
                  />
                ))
              ) : (
                <Typography>No transaction</Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box mx={{ md: 10 }}>{/* <DashTable /> */}</Box>
    </>
  );
};

export default UserDash;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useUser from "../lib/useUser";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import axiosInstances from "../hooks/axiosInstances";
import AllUsersTable from "../Components/Tables/AllUsersTable";
import { LoadingButton } from "@mui/lab";
import ButtonComp from "../Components/Buttons/ButtonComp";
import { InputText } from "../Components/TextFields/InputText";
import { useFormik } from "formik";
import { formatNumber } from "../utils/FormatedNumber";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../utils/formSlice";
import { fetchUsers } from "../utils/constants";

const Users = () => {
  const user = useUser((state) => state.user);
  const [editId, setEditId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { isLoading, userData } = useSelector((state: any) => state.form);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    if (openDialog) {
      setEditId("");
      formik.resetForm();
    }
    setOpenDialog(!openDialog);
  };

  const handleEdit = async (id: string) => {
    setEditId(id);
    handleClickOpen();
  };

  const setMultipleValues = (values: { [key: string]: any }) => {
    Object.keys(values).forEach((key) => {
      formik.setFieldValue(key, values[key]);
    });
  };

  const fetchSingleUser = async (id: string) => {
    const filterData = await userData.filter((data: any) => data._id === id);
    const data = filterData[0];

    setMultipleValues({
      username: data.username,
      email: data.email,
      fullname: data.fullname,
      balance: data.balance,
      phone: data.phone,
      contact: data.contact,
      isBlocked: data.isBlocked,
      isVerified: data.isVerified,
      kyc: data.kyc,
      totalDeposited: data.totalDeposited,
      totalExpenses: data.totalExpenses,
    });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      fullname: "",
      balance: 0,
      totalDeposited: 0,
      totalExpenses: 0,
      phone: "",
      contact: "",
      isBlocked: false,
      isVerified: false,
      kyc: false,
    },
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstances
        .put(`users/${editId}`, value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          formik.resetForm();
          fetchUsers(dispatch);
          handleClickOpen();
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  useEffect(() => {
    if (editId) {
      fetchSingleUser(editId);
    }
  }, [editId]);

  return (
    <>
      {user.role === "ADMIN" ? (
        <>
          <HeaderTextButton headerName="Users" />

          <Box mt="40px">
            <AllUsersTable data={userData} handleEdit={handleEdit} />
          </Box>

          <Dialog
            open={openDialog}
            onClose={handleClickOpen}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle sx={{ fontWeight: "bold" }}>Edit User</DialogTitle>
            <DialogContent>
              <DialogContentText mb={"15px"}>
                Overview of user's profile.
              </DialogContentText>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ gap: 3 }}>
                  <InputText
                    id="username"
                    label="Username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                  <InputText
                    id="email"
                    label="Email Address"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <InputText
                    id="fullname"
                    label="Full Name"
                    value={formik.values.fullname}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fullname && Boolean(formik.errors.fullname)
                    }
                    helperText={
                      formik.touched.fullname && formik.errors.fullname
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      gap: 2,
                    }}
                  >
                    <InputText
                      id="balance"
                      label="Balance"
                      value={formatNumber(formik.values.balance)}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.balance && Boolean(formik.errors.balance)
                      }
                      helperText={
                        formik.touched.balance && formik.errors.balance
                      }
                    />
                    <InputText
                      id="phone"
                      label="Phone Number"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { lg: "row", xs: "column" },
                      gap: 2,
                    }}
                  >
                    <InputText
                      id="totalDeposited"
                      label="Total Deposit"
                      disabled
                      value={formatNumber(formik.values.totalDeposited)}
                    />
                    <InputText
                      id="totalExpenses"
                      label="Total Expenses"
                      disabled
                      value={formatNumber(formik.values.totalExpenses)}
                    />
                  </Box>
                  <InputText
                    id="contact"
                    label="Contact Address"
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contact && Boolean(formik.errors.contact)
                    }
                    helperText={formik.touched.contact && formik.errors.contact}
                  />
                </Box>
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    flexDirection: { lg: "row", xs: "column" },
                    gap: 2,
                    width: "100%",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.isBlocked &&
                        Boolean(formik.errors.isBlocked)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        IsBlocked
                      </InputLabel>
                      <Select
                        labelId="IsBlocked"
                        id="isBlocked"
                        name="isBlocked"
                        label="IsBlocked"
                        value={formik.values.isBlocked}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.isBlocked &&
                          Boolean(formik.errors.isBlocked)
                        }
                      >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.isBlocked && formik.errors.isBlocked && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.isBlocked}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.isVerified &&
                        Boolean(formik.errors.isVerified)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        IsVerified
                      </InputLabel>
                      <Select
                        labelId="IsVerified"
                        id="isVerified"
                        name="isVerified"
                        label="IsVerified"
                        value={formik.values.isVerified}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.isVerified &&
                          Boolean(formik.errors.isVerified)
                        }
                      >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.isVerified && formik.errors.isVerified && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                      >
                        {formik.errors.isVerified}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <FormControl
                    fullWidth
                    error={formik.touched.kyc && Boolean(formik.errors.kyc)}
                  >
                    <InputLabel id="demo-select-small-label">KYC</InputLabel>
                    <Select
                      labelId="KYC"
                      id="kyc"
                      name="kyc"
                      label="KYC"
                      value={formik.values.kyc}
                      onChange={formik.handleChange}
                      error={formik.touched.kyc && Boolean(formik.errors.kyc)}
                    >
                      <MenuItem value="true">True</MenuItem>
                      <MenuItem value="false">False</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.touched.kyc && formik.errors.kyc && (
                    <Typography color="error" fontSize={"0.75rem"} ml={"14px"}>
                      {formik.errors.kyc}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: 2 }}>
                  {isLoading ? (
                    <LoadingButton loading variant="outlined" fullWidth>
                      Submit
                    </LoadingButton>
                  ) : (
                    <ButtonComp
                      text="Update User"
                      type="submit"
                      variant="contained"
                      fullWidth
                      disableElevation
                    />
                  )}
                </Box>
              </form>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <Typography>Access denied</Typography>
      )}
    </>
  );
};

export default Users;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HeaderTextButton } from "../Components/Header/HeaderTextButton";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
// import { TransactionList } from "../Components/others/TransactionList";
import { Item } from "../utils/ItemStyle";
import { Link } from "react-router-dom";
import { InputText } from "../Components/TextFields/InputText";
import ButtonComp from "../Components/Buttons/ButtonComp";
import mtnLogo from "../assets/Images/mtn.png";
import gloLogo from "../assets/Images/glo.png";
import airtelLogo from "../assets/Images/airtel.png";
import mobileLogo from "../assets/Images/9mobile.png";
import { useFormik } from "formik";
import axiosInstances from "../hooks/axiosInstances";
import { IProducts, IProductPrice } from "../types/common";
import { removeDuplicates } from "../utils/utils";
import { formatNumber } from "../utils/FormatedNumber";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoading,
  setMessage,
  setSuccess,
  setSubmitted,
  clearMessage,
} from "../utils/formSlice";
import { validatePurchaseData } from "../utils/validationScheme";
import { TransactionList } from "../Components/others/TransactionList";
import { useColors } from "../utils/useColor";
import {
  fetchDepositedAmount,
  fetchExpensesAmount,
  fetchTransactions,
  fetchUserBalance,
} from "../utils/constants";

const Data = () => {
  const [mtn, setMtn] = useState(false);
  const [glo, setGlo] = useState(false);
  const [airtel, setAirtel] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [dataType, setDataType] = useState<IProducts[]>([]);
  const [dataBundle, setDataBundle] = useState<IProductPrice[]>([]);
  const { error } = useColors();
  const dispatch = useDispatch();

  const { transactions, products, productPrice, isLoading } = useSelector(
    (state: any) => state.form
  );

  const formik = useFormik({
    initialValues: {
      network: "",
      type: "",
      bundle: "",
      number: "",
    },
    validationSchema: validatePurchaseData,
    onSubmit: (value) => {
      dispatch(setLoading(true));
      axiosInstances
        .post("/users/data", value)
        .then((res) => {
          dispatch(setMessage(res.data.message));
          fetchTransactions(dispatch);
          fetchUserBalance(dispatch);
          fetchDepositedAmount(dispatch);
          fetchExpensesAmount(dispatch);
          formik.resetForm();
          dispatch(setSuccess());
          dispatch(setSubmitted());
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          dispatch(setLoading(false));
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        });
    },
  });

  const filterProducts = removeDuplicates(
    products.filter(
      (data: any) => data.serviceName === "Data" && data.visibility === true
    )
  );

  useEffect(() => {
    if (formik.values.network === "MTN") {
      setMtn(true);
      setGlo(false);
      setAirtel(false);
      setMobile(false);
    }
    if (formik.values.network === "GLO") {
      setGlo(true);
      setMobile(false);
      setMtn(false);
      setAirtel(false);
    }
    if (formik.values.network === "AIRTEL") {
      setAirtel(true);
      setGlo(false);
      setMobile(false);
      setMtn(false);
    }
    if (formik.values.network === "9MOBILE") {
      setMobile(true);
      setMtn(false);
      setAirtel(false);
      setGlo(false);
    }

    if (formik.values.network) {
      const dataType = products.filter(
        (data: any) =>
          data.category === formik.values.network && data.visibility === true
      );

      setDataType(dataType);
    }

    if (formik.values.type) {
      const filteredPrice = productPrice.filter(
        (data: any) => data.product === formik.values.type
      );

      setDataBundle(filteredPrice);
    }
  }, [formik]);

  const filteredTransaction = transactions.filter(
    (item: any) => item.description === "Data purchase"
  );

  const last5Transactions = filteredTransaction.slice(-5).reverse();
  return (
    <>
      <HeaderTextButton headerName="Data Purchase" />

      <Box sx={{ flexGrow: 0, mt: "30px" }}>
        <Grid
          container
          spacing={0}
          columns={16}
          gap={{ xs: 2, sm: 1, md: 0 }}
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column", sm: "row" },
          }}
        >
          <Grid xs={16} sm={8} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Box
                sx={{
                  mt: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    width: { lg: "60px", xs: "50px" },
                    height: { lg: "60px", xs: "50px" },
                    borderRadius: "50%",
                    background: "red",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: mtn ? 1 : 0.2,
                  }}
                >
                  <img
                    src={mtnLogo}
                    alt="MTN"
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { lg: "60px", xs: "50px" },
                    height: { lg: "60px", xs: "50px" },
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: glo ? 1 : 0.2,
                  }}
                >
                  <img
                    src={gloLogo}
                    alt="GLO"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { lg: "60px", xs: "50px" },
                    height: { lg: "60px", xs: "50px" },
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: airtel ? 1 : 0.2,
                  }}
                >
                  <img
                    src={airtelLogo}
                    alt="AIRTEL"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { lg: "60px", xs: "50px" },
                    height: { lg: "60px", xs: "50px" },
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "none",
                    cursor: "pointer",
                    opacity: mobile ? 1 : 0.2,
                  }}
                >
                  <img
                    src={mobileLogo}
                    alt="9 Mobile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ mx: "30px", my: "40px" }}>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.network && Boolean(formik.errors.network)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Network Provider
                      </InputLabel>
                      <Select
                        labelId="network"
                        id="network"
                        name="network"
                        label="Network Provider"
                        value={formik.values.network}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.network &&
                          Boolean(formik.errors.network)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {filterProducts &&
                          filterProducts.map((data, index) => (
                            <MenuItem key={index} value={data.category}>
                              {data.category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.network && formik.errors.network && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                        textAlign={"left"}
                      >
                        {formik.errors.network}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={formik.touched.type && Boolean(formik.errors.type)}
                    >
                      <InputLabel id="demo-select-small-label">
                        Data Type
                      </InputLabel>
                      <Select
                        id="type"
                        name="type"
                        label="Data Type"
                        value={formik.values.type}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {dataType &&
                          dataType.map((data, index) => (
                            <MenuItem key={index} value={data._id}>
                              {data.product}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    {formik.touched.type && formik.errors.type && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                        textAlign={"left"}
                      >
                        {formik.errors.type}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "15px" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.bundle && Boolean(formik.errors.bundle)
                      }
                    >
                      <InputLabel id="demo-select-small-label">
                        Data bundle
                      </InputLabel>
                      <Select
                        id="bundle"
                        name="bundle"
                        label="Data bundle"
                        value={formik.values.bundle}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.bundle && Boolean(formik.errors.bundle)
                        }
                        sx={{
                          textAlign: "left",
                        }}
                      >
                        {dataBundle &&
                          [...dataBundle]
                            .sort((a, b) => a.price - b.price)
                            .map((data, index) => (
                              <MenuItem key={index} value={data._id}>
                                {`${data.volume} - ${formatNumber(
                                  data.price
                                )} - ${data.days}days`}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                    {formik.touched.bundle && formik.errors.bundle && (
                      <Typography
                        color="error"
                        fontSize={"0.75rem"}
                        ml={"14px"}
                        textAlign={"left"}
                      >
                        {formik.errors.bundle}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ mb: "5px" }}>
                    <InputText
                      id="number"
                      label="Phone Number"
                      value={formik.values.number}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.number && Boolean(formik.errors.number)
                      }
                      helperText={formik.touched.number && formik.errors.number}
                    />
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        mb: "10px",
                        textAlign: "left",
                        "& span": {
                          color: error.main,
                        },
                      }}
                    >
                      To Check Data Balance Dial: <span>*323# or *323*4#</span>
                    </Typography>
                  </Box>

                  {isLoading ? (
                    <LoadingButton loading variant="outlined" fullWidth>
                      Submit
                    </LoadingButton>
                  ) : (
                    <ButtonComp
                      variant="contained"
                      text="Proceed"
                      type="submit"
                      fullWidth
                      disableElevation
                    />
                  )}
                </Box>
              </form>
            </Item>
          </Grid>
          <Grid xs={16} sm={8} md={8}>
            <Item
              sx={{
                overflow: "hidden",
                mr: { lg: "20px", xs: "0px" },
                p: 0,
              }}
            >
              <Stack sx={{ p: "10px", minHeight: "250px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "black",
                  }}
                >
                  <Typography fontWeight={"bold"}>
                    Transactions History
                  </Typography>
                  <Link
                    to="/transactions"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <Typography fontWeight={"bold"} sx={{ cursor: "pointer" }}>
                      See All
                    </Typography>
                  </Link>
                </Box>
                <Stack sx={{ mt: "20px" }} gap={1}>
                  {last5Transactions ? (
                    last5Transactions.map((data: any, index: number) => (
                      <TransactionList
                        key={index}
                        amount={data.amount}
                        description={data.description}
                        date={data && data.createdAt}
                      />
                    ))
                  ) : (
                    <Typography>No transaction</Typography>
                  )}
                </Stack>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Data;

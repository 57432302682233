import { Box, CircularProgress, styled } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../lib/useUser";
import axiosInstances from "../hooks/axiosInstances";
import { clearMessage, setMessage, setSuccess } from "../utils/formSlice";
import { useDispatch } from "react-redux";
import { fetchTransactions, fetchUserBalance, fetchDepositedAmount, fetchExpensesAmount } from "../utils/constants";

const Overlay = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
});

const PaymentVerify = () => {
  const location = useLocation();
  const updateUser = useUser((state) => state.updateUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const reference = queryParams.get("reference");
      const value = {
        reference: reference,
      };

      await axiosInstances
        .put("/transactions/payment", value)
        .then((res) => {
          updateUser(res.data.user);
          dispatch(setSuccess());
          dispatch(setMessage(res.data.message));
          fetchTransactions(dispatch);
          fetchUserBalance(dispatch);
          fetchDepositedAmount(dispatch);
          fetchExpensesAmount(dispatch);
          navigate("/wallet");
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000);
        })
        .catch((error) => {
          dispatch(setMessage(error.response.data.error));
          navigate("/wallet");
          setTimeout(() => {
            dispatch(clearMessage());
          }, 3000); // Handle error
        });
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  return (
    <>
      <Overlay>
        <CircularProgress color="primary" />
      </Overlay>
    </>
  );
};

export default PaymentVerify;

import React, { FC, useState } from "react";
import { IUsers } from "../../types/common";
import {
  styled,
  TableCell,
  Theme,
  tableCellClasses,
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TableBody,
  Tooltip,
} from "@mui/material";
// import { useColors } from "../../utils/useColor";
// import { useStyles } from "../../utils/useStyles";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { formatNumber } from "../../utils/FormatedNumber";

interface Props {
  data: IUsers[];
  handleEdit: (id: string) => void;
  wallet?: boolean;
}

const AllUsersTable: FC<Props> = ({ data, handleEdit, wallet }) => {
  const [page, setPage] = useState(0);

  //   const { success, error } = useColors();

  //   const style = useStyles()

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchText, setSearchText] = useState("");

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setPage(0);
  };

  const filteredRows = searchText
    ? data.filter(
        (row) =>
          row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.fullname?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.username?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.balance
            ?.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      )
    : data;

  const StyledTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <>
      <Box>
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearch}
          style={{ marginBottom: "1rem" }}
          size="small"
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell align="left">Full Name</StyledTableCell>
                <StyledTableCell align="left">Username</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                {wallet && (
                  <StyledTableCell align="left">Balance</StyledTableCell>
                )}
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filteredRows) && filteredRows.length > 0 ? (
                [...filteredRows]
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">{row.fullname}</TableCell>
                      <TableCell align="left">{row.username}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      {wallet && (
                        <TableCell align="left">
                          {formatNumber(row.balance as number)}
                        </TableCell>
                      )}
                      <TableCell align="left" width="10%">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip title="update balance">
                            <Box onClick={() => handleEdit(row._id)}>
                              <BorderColorOutlinedIcon
                                sx={{ cursor: "pointer", color: "green" }}
                              />
                            </Box>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default AllUsersTable;

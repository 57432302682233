/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, FC } from "react";
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Legend,
} from "chart.js";
import { useColors } from "../../utils/useColor";

interface Props {
  labels: string[];
  datasets: number[];
}

const MyChart: FC<Props> = ({ labels, datasets }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { primary, secondary } = useColors();

  useEffect(() => {
    // Register the necessary components
    Chart.register(
      BarController,
      BarElement,
      CategoryScale,
      LinearScale,
      Title,
      Legend
    );

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        const myChart = new Chart(ctx, {
          type: "bar", // Specify the chart type
          data: {
            labels: labels,
            datasets: [
              {
                label: "Transactions in last 28 days",
                data: datasets,
                backgroundColor: primary.main,
                borderColor: secondary.main,
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            hover: {
              mode: "nearest", 
              intersect: true, 
            },
            plugins: {
              tooltip: {
                enabled: true, 
                callbacks: {
                  label: function (context) {
                    return `${context.label}: ${context.raw}`;
                  },
                },
              },
              legend: {
                position: "top",
              },
            },
          },
        });

        return () => {
          myChart.destroy();
        };
      }
    }
  }, [labels, datasets]);

  return <canvas ref={canvasRef} style={{ height: "250px", width: "100%" }} />;
};

export default MyChart;
